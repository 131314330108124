<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'employees' }">Schedule</router-link>
        </li>
        <li class="breadcrumb-item active">Add</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">New Schedule</h2>
        </div>
        <div class="col-md-1 text-right">
          <router-link
            :to="{ name: 'schedules' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <flash-message class="myCustomClass"></flash-message>
      <div v-if="this.$store.state.loader">
          <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h2>Add Schedule</h2>
              </div>
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label">Employee</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.id.$model"
                    :validator="$v.form.id"
                    @change="setUserData"
                    :options="employees"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.id.$error == true"
                    >Please select employee </span
                  >
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label">Start time</label>
                            <div>
                            <vue-timepicker
                                format="hh:mm A"
                                v-model="$v.form.intime.$model"
                                :validator="$v.form.intime"
                                auto-scroll
                                @change="timeChangeHandler($event,'intime')"
                            ></vue-timepicker>
                            </div>
                            <span
                                class="error "
                                v-if="$v.form.intime.$error == true"
                                >Start time is required </span
                            >
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label">Off time</label>
                            <div>
                            <vue-timepicker
                                format="hh:mm A"
                                v-model="$v.form.outime.$model"
                                :validator="$v.form.outime"
                                auto-scroll
                                @change="timeChangeHandler($event,'outime')"
                            ></vue-timepicker>
                            </div>
                            <span
                                class="error "
                                v-if="$v.form.outime.$error == true"
                                >Off time is required </span
                            >
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">From</label>
                    <datepicker
                    typeable="true"
                    placeholder="Select Date"
                    class="form-control"
                    v-model="$v.form.datefrom.$model"
                    :validator="$v.form.datefrom"
                    :format="customFormatter"
                  ></datepicker>
                  <span
                    class="error "
                    v-if="$v.form.datefrom.$error == true"
                    >From date is required </span>
                </div>
                <div class="mb-3">
                    <label class="form-label">To</label>
                    <datepicker
                    typeable="true"
                    placeholder="Select Date"
                    class="form-control"
                    v-model="$v.form.dateto.$model"
                    :validator="$v.form.dateto"
                    :format="customFormatter"
                  ></datepicker>
                  <span
                    class="error "
                    v-if="$v.form.dateto.$error == true"
                    >To date is required </span>
                </div>
                <div class="mb-3">
                    <label class="form-label">Total hours</label>
                    <input
                        type="number"
                        placeholder="Total Hours"
                        class="form-control"
                        v-model="$v.form.hours.$model"
                        :validator="$v.form.hours"
                    />
                  <span
                    class="error "
                    v-if="$v.form.hours.$error == true"
                    >Total hour is required </span>
                </div>
                <div class="mb-3 ck-box">
                    <label class="form-label">Rest Days</label>
                    <div>
                        <input
                            type="checkbox"
                            v-model="$v.form.restday.$model"
                            :validator="$v.form.restday"
                            id="sunday"
                            value="Sunday"
                        />
                        <label for="sunday">Sunday</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            v-model="$v.form.restday.$model"
                            :validator="$v.form.restday"
                            id="monday"
                            value="Monday"
                        />
                        <label for="monday">Monday</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            v-model="$v.form.restday.$model"
                            :validator="$v.form.restday"
                            id="tue"
                            value="Tuesday"
                        />
                        <label for="tue">Tuesday</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            v-model="$v.form.restday.$model"
                            :validator="$v.form.restday"
                            id="wed"
                            value="Wednesday"
                        />
                        <label for="wed">Wednesday</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            v-model="$v.form.restday.$model"
                            :validator="$v.form.restday"
                            id="thur"
                            value="Thursday"
                        />
                        <label for="thur">Thursday</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            v-model="$v.form.restday.$model"
                            :validator="$v.form.restday"
                            id="fri"
                            value="Friday"
                        />
                        <label for="fri">Friday</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            v-model="$v.form.restday.$model"
                            :validator="$v.form.restday"
                            id="sat"
                            value="Saturday"
                        />
                        <label for="sat">Saturday</label>
                    </div>
                  <span
                    class="error "
                    v-if="$v.form.restday.$error == true"
                    >Rest days is required </span>
                </div>
                <div class="text-right">
                    <button type="submit" class="btn btn-outline-primary space" >Save</button>
                    <router-link :to="{name: 'schedules'}" class="btn btn-outline-primary" >Cancel</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import moment from 'moment';
import "vue2-timepicker/dist/VueTimepicker.css";
import axios from 'axios';
export default {
  components: { Datepicker, VueTimepicker},
  props: ["id"],
  data() {
    return {
      form: {
        id: null,
        employee: null,
        intime: '10:00 AM',
        outime: '07:00 PM',
        datefrom: null,
        dateto: null,
        hours: null,
        restday: [],
      },
    };
  },
  validations: {
    form: {
      id: {
        required,
      },
      intime: {
        required,
      },
      outime: {
        required,
      },
      datefrom: {
        required,
      },
      dateto: {
        required,
      },
      hours: {
        required,
      },
      restday: {
          required
      }
    },
  },
  created() {
    this.$store.dispatch('switchLoader',true);
      this.$store.dispatch("loadEmployees");
  },
  mounted() {
    
    console.log('users ', this.employees);
  },
  computed: {
    employees() {
      return this.$store.state.employees.map((employee) => {
        return {
          value: employee.id,
          text: employee.lastname +', '+employee.firstname,
        };
      });
    },
  },
  methods: {
    customFormatter(date) {
      return moment(date).format('yyyy-MM-DD');
    },
    timeChangeHandler(event,time){
        if(time == 'intime'){
            this.form.intime = event.displayTime;
        }
        else{
            this.form.outime = event.displayTime;
        }
        
    },
    setUserData(){
        console.log(this.form.id);
        let empDetail = this.$store.state.employees.filter((emp) => {  return emp.id == this.form.id})[0];
        console.log(empDetail);
        this.form.employee = empDetail.lastname+', '+empDetail.firstname;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.form.datefrom = this.customFormatter(this.form.datefrom);
      this.form.dateto = this.customFormatter(this.form.dateto);
      axios
        .post("/schedules/add", this.form)
        .catch(({ response }) => {
          console.log(response);
          this.flashMessage.error({
            message: "Something went wrong!",
            time: 3000,
          });
        })
        .then(({ data }) => {
          console.log(data);
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "schedules" });
          } else {
            this.flashMessage.error({
              message: data.error,
              time: 3000,
            });
          }
        });
    },
  },
};
</script>